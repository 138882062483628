import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { I18nBehavior } from '../../behaviors/i18n-behavior.js'
import { ParticipationBehavior } from '../../behaviors/participation-behavior.js'
import { QuestionItemStyle } from './style.js';

export const QuestionItem = class extends mixinBehaviors([I18nBehavior, ParticipationBehavior], PolymerElement) {
  static get is() { return 'question-item'; }
  static get behaviors() { return [I18nBehavior, ParticipationBehavior] }
  static get properties() { 
    return { 
      item: Object,
      participation: {
        type: Boolean,
        value: false
      },
      participationCounter: {
        type: Number,
        value: 0,
        notify: true
      },
      participationText: {
        computed: '_getParticipationText(participationCounter)'
      },
    } 
  }
  static get template() {
    return html`
      ${QuestionItemStyle}
      <div class="question-item" id$="question_[[item.id]]">
        <div class="item-data question-item-data">
          <div class="item-author question-item-author">[[item.author]]</div>
          <div class='item-date question-item-date'>[[item.created_at]]</div>
          <div class="item-text question-item-text">[[item.text]]</div>
        </div>
        <div class="item-actions question-item-actions">
          <share-button url$="[[item.share_url]]" text$="[[item.text]]"></share-button>
          <template is="dom-if" if="[[participation]]">
            <participation-fab logged$="[[logged]]" login-path$="[[loginPath]]" type="vote_up" participation-path$="[[item.participation_path]]" done$="[[item.already_voted]]" resource="question" position="relative" remote counter="[[participationCounter]]"></participation-fab>
          </template>
          <template is="dom-if" if="[[item.participation]]">
            <div class="question-item-votes">[[participationText]]</div>
          </template>
        </div>
      </div>
    `;
  }
  _getParticipationText(item) {
    let text;
    if (this.participationCounter === 1 ) {
      text = I18nBehavior.i18n('question_item.votes_count_one')
    } else if (this.participationCounter != null) {
      text = this.participationCounter + I18nBehavior.i18n('question_item.votes_count_other')
    }
    return text
  }
  _updateParticipationCounter(event) {
    // alert('you are in _updateParticipationCounter')
    this.participationCounter = this.participationCounter + event.detail
  }
  connectedCallback() {
    super.connectedCallback();
    var search_item = window.location.search.match(/question_id=(\d+)\&?/)
    if (search_item && search_item[1] === String(this.item.id)) {
      this.className += ' active'
    }
    this.participationCounter = this.item.participation
    // TOREVIEW:
    // this.$.fab.addEventListener('updateCounter', this._updateParticipationCounter);
  }
}

customElements.get(QuestionItem.is) || customElements.define(QuestionItem.is, QuestionItem);
