import LocalizedStrings from 'localized-strings';

let strings = new LocalizedStrings(
  {
    es:{
      debates_list: {
        see_more: 'Más propuestas de Gobierno',
        see_more_meetings: 'Ver todas las iniciativas Topaketa'
      },
      events_list: {
        live_streaming: 'Conexión en directo',
        announced_streaming: 'En breve comenzará la emisión',
        programmed_streaming: 'Próxima emisión',
        see_more: 'Más eventos'
      },
      news_list: {
        see_more: 'Más noticias'
      },
      participation_fab: {
        vote_up_question: 'Votar por esta pregunta',
        unvote_up_question: 'Retirar mi voto de esta pregunta',
        vote_saved: '¡Tu voto se ha guardado!',
        vote_destroyed: '¡Tu voto se ha eliminado!',
        comment: 'Enviar un comentario',
        create_proposal: 'Crea una petición pública',
        create_question: 'Añadir una pregunta',
        error_on_vote: 'Solo pueden votarse tantas preguntas como temas en cada Topaketa. Has alcanzado el límite máximo de votos. Si deseas votar esta pregunta, por favor, retira antes tu voto de otra pregunta.',
        remote_error: 'Se ha producido un error al enviar la petición. Por favor, reinténtalo pasados unos segundos.'
      },
      proposals_list: {
        see_more: 'Más peticiones ciudadanas'
      },
      question_item: {
        votes_count_one: '1 voto',
        votes_count_other: ' votos'
      },
      questions_list: {
        see_more: 'Más preguntas'
      },
      share_button: {
        title: 'Compartir',
        facebook: 'Compartir en Facebook',
        twitter: 'Compartir en X',
        clipboard: {
          title: "Copiar al portapapeles",
          success: '¡Copiado!',
          error: 'Usa Ctrl+C para copiar al portapapeles'
        }
      },
      surveys_list: {
        see_more: 'Más encuestas'
      }
    },
    eu: {
      debates_list: {
        see_more: 'Gobernuaren proposamen gehiago',
        see_more_meetings: 'Ikusi Topaketa ekimen guztiak'
      },
      events_list: {
        live_streaming: 'Zuzeneko konexioa',
        announced_streaming: 'Laster hasiko da emankizuna',
        programmed_streaming: 'Hurrengo emankizuna',
        see_more: 'Más eventos*'
      },
      news_list: {
        see_more: 'Berri gehiago'
      },
      participation_fab: {
        vote_up_question: 'Galdera honen alde bozkatu',
        unvote_up_question: 'Nire botoa galdera honetatik kendu.',
        vote_saved: 'Zure botoa gorde egin da!',
        vote_destroyed: 'Zure botoa ezabatu egin da!',
        comment: 'Enviar un comentario*',
        create_proposal: 'Crea una petición pública*',
        create_question: 'Galdera bat gehitu',
        error_on_vote: 'Gai adina galdera baino ezin dira bozkatu Topaketa bakoitzean. Botoen gehienezko mugara iritsi zara. Galdera hau bozkatu nahi baduzu, kendu lehenago zure botoa beste galdera batetik.',
        remote_error: 'Errore bat gertatu da eskaera bidaltzean. Mesedez, saiatu berriro segundo batzuk igaro ondoren.'
      },
      proposals_list: {
        see_more: 'Herritarren eskaera gehiago'
      },
      question_item: {
        votes_count_one: 'Boto 1',
        votes_count_other: ' boto'
      },
      questions_list: {
        see_more: 'Galdera gehiago'
      },
      share_button: {
        title: 'Partekatu',
        facebook: 'Facebooken partekatu',
        twitter: 'X-en partekatu',
        clipboard: {
          title: "Kopiatu arbelean",
          success: 'Kopiatuta!',
          error: 'Erabili Ctrl+C arbelean kopiatzeko'
        }
      },
      surveys_list: {
        see_more: 'Inkesta gehiago'
      }
    },
    en: {
      debates_list: {
        see_more: 'More debates',
        see_more_meetings: 'View all Topaketa initiatives'
      },
      events_list: {
        live_streaming: 'Live streaming',
        announced_streaming: 'Streaming will start soon',
        programmed_streaming: 'Next streaming',
        see_more: 'More events'
      },
      news_list: {
        see_more: 'More news'
      },
      participation_fab: {
        vote_up_question: 'Vote for this question',
        unvote_up_question: 'Withdraw my vote from this question',
        vote_saved: 'Your vote has been saved!',
        vote_destroyed: 'Your vote has been deleted!',
        comment: 'Send comment',
        create_proposal: 'Crea una petición pública*',
        create_question: 'Add a question',
        error_on_vote: 'You can only vote for as many questions as there are topics in each Topaketa. You have reached the maximum number of votes. If you wish to vote on this question, please remove your vote from another question first.',
        remote_error: 'An error occurred while sending the request. Please try again after a few seconds.'
      },
      proposals_list: {
        see_more: 'More citizen petitions'
      },
      question_item: {
        votes_count_one: '1 vote',
        votes_count_other: ' votes'
      },
      questions_list: {
        see_more: 'More questions'
      },
      share_button: {
        title: 'Share',
        facebook: 'Share in Facebook',
        twitter: 'Share in X',
        clipboard: {
          title: "Copy to clipboard",
          success: 'Copied!',
          error: 'Use Ctrl+C to copy to clipboard'
        }
      },
      surveys_list: {
        see_more: 'More surveys'
      }
    }
  },
  {
    /* options */
  }
);

export const I18nBehavior = {
  i18n: function(localize_string){
    strings.setLanguage(this.getLocale())
    return strings.getString(localize_string)
  },
  getLocale: function(){
    return window.location.pathname.split('/')[2] || 'es';
  },
}
