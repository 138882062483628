import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { I18nBehavior } from '../../behaviors/i18n-behavior.js'
import * as Clipboard from 'clipboard';
import { Tooltip, Popover } from 'bootstrap';
import { ShareButtonStyle } from './style.js';

export const ShareButton = class extends mixinBehaviors([I18nBehavior], PolymerElement) {
  static get is() { return 'share-button'; }
  static get behaviors() { return [I18nBehavior] }
  static get properties() { 
    return { 
      url: {
        type: String,
        value: window.location.href
      },
      text: String,
      fab: Boolean,
      escapedUrl: {
        computed: '_getEscapedUrl(url)'
      },
      textWithSite: {
        computed: '_getTextWithSite(text)'
      },
      escapedText: {
        computed: '_getEscapedText(textWithSite)'
      },
      twitterText: {
        computed: '_getTwitterText(url, escapedText)'
      },
      facebookUrl: {
        computed: '_getFacebookUrl(escapedUrl, escapedText)'
      },
      twitterUrl: {
        computed: '_getTwitterUrl(escapedUrl, twitterText)'
      },
      popover: String
    }
  }
  static get template() {
    return html`
      ${ShareButtonStyle}
      <div class$="share-btn-widget fab-[[fab]]">
        <a class="share-btn only-icon" on-click="toggle" title$="[[i18n('title')]]" data-share-title$="[[i18n('title')]]" data-share-text$="[[title]]" href="#" data-bs-original-title$="[[i18n('title')]]" id="btn">
          <i class="fas fa-share-alt"></i>
          <span>[[i18n('title')]]</span>
        </a>
        <div class$="share-popover fab-[[fab]] d-none" id="popover">
          <div class="share-links">
            <a rel="noopener noreferrer" target="_blank" class="share-link share-facebook" title$="[[i18n('facebook')]]" href$="[[facebookUrl]]">
              <i class="fab fa-facebook"></i>
            </a>
            <a rel="external" target="_blank" class="share-link share-twitter" title$="[[i18n('twitter')]]" href$="[[twitterUrl]]">
              <i class="fab fa-x-twitter"></i>
            </a>
            <button class="share-link copy-to-clipboard" id="copyToClipboard" data-clipboard-text$="[[url]]" data-bs-toggle="tooltip" data-bs-original-title$="[[i18n('clipboard.title')]]" title$="[[i18n('clipboard.title')]]">
              <i class="fas fa-clipboard"></i>
            </button>
          </div>
        </div>
      </div>
    `;
  }
  constructor() {
    super();
  }
  connectedCallback() {
    super.connectedCallback();
    if(navigator.canShare) {
      this.$.btn.addEventListener('click', event => {
        this.toggleNavigatorShare(event)
      })
    } else {
      this.bindSharePopover()
      this.$.btn.addEventListener('click', event => {
        this.toggleSharePopover(event)
      })
    }
  }

  _getTextWithSite(text){
    return `${text} - ${document.head.querySelector("[name=author][content]").content}`;
  }

  _getEscapedUrl(url) {
    return encodeURIComponent(url.split('-')[0])
  }

  _getEscapedText(textWithSite) {
    return encodeURIComponent(textWithSite)
  }

  _getTwitterText(url, escapedText) {
    if (escapedText != undefined) {
      return escapedText.slice(0, 280 - url.length)  
    } else {
      return ''
    }
  }

  _getFacebookUrl(escapedUrl, escapedText) {
    return `http://www.facebook.com/sharer/sharer.php?text=${escapedText}&u=${escapedUrl}`
  }

  _getTwitterUrl(escapedUrl, twitterText) {
    return `https://twitter.com/intent/tweet?text=${twitterText}&url=${escapedUrl}`
  }

  i18n(string){
    return I18nBehavior.i18n(`share_button.${string}`)
  }

  toggleNavigatorShare(event){
    event.preventDefault()
    navigator.share({
      title: this.textWithSite,
      text: this.textWithSite,
      url: this.url
    })
    .then(_ => {})
    .catch(error => {
      console.log('navigator.share error:', error)
    });
  }

  bindSharePopover(){
    let shareAllowList = Tooltip.Default.allowList
    shareAllowList.button = ['data-clipboard-text', 'data-clipboard-success', 'data-clipboard-error', 'data-bs-toggle', 'data-bs-original-title', 'title']
    shareAllowList.input = ['value']

    this.sharePopover = new Popover(this.$.btn, {
      html: true,
      container: 'body',
      content: this.$.popover.innerHTML,
      placement: 'left',
      customClass: this.fab ? 'share-popover fab-true' : 'share-popover',
      allowList: shareAllowList
    })
  }

  toggleSharePopover(event){
    event.preventDefault()
    this.sharePopover.show()
    this.$.btn.addEventListener('shown.bs.popover', event2 => {
      event2.stopImmediatePropagation()
      this.bindCopyToClipboard()
    })
  }
  bindCopyToClipboard() {
    let clipboard = new Clipboard('.copy-to-clipboard');

    clipboard.on('success', event => {
      this.showClipboardResponse('success')
      event.clearSelection();
    });

    clipboard.on('error', event => {
      this.showClipboardResponse('error')
    });
  }
  showClipboardResponse(response){
    // this.sharePopover.update()
    let tooltip = new Tooltip(this, {title: this.i18n(`clipboard.${response}`), container: 'body', customClass: 'copy-to-clipboard-tooltip'})
    tooltip.show()
    setTimeout(function(){ tooltip.hide();tooltip.disable() }, 2000);
  }

}

customElements.get(ShareButton.is) || customElements.define(ShareButton.is, ShareButton);
