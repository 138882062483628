import { html } from '@polymer/polymer/polymer-element.js'
import { SharedStyle } from '../shared-style.js';

export const PoliticiansListStyle = html`
  ${SharedStyle}
  <style>
:host{

}

.politician-item, .politician-item-info {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
}

.politician-item .politician-item-image {
  width: auto;
  padding-right: 0.2rem;
}

.politician-item .politician-item-image img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.politician-item .politician-item-data {
  padding-left: 0.5rem;
}

.politician-item .politician-item-data h2 {
  width: auto;
  font: var(--h2-font);
  margin: 0 0 0.5rem;
}

.politician-item .politician-personal-sign {
  width: 40px;
}

.politician-item .politician-personal-sign.off {
  display: none
}

.politician-item .politician-personal-sign button {
  all: unset;
}

.politician-item .politician-personal-sign img {
  width: 100%;
  border-radius: 0.2rem;
  box-shadow: var(--box-shadow-lg);
}

.politician-item .politician-item-data .politician-item-role {
  font: var(--h3-font);
  font-weight: 500;
  color: var(--text-color);
}

  </style>
`;
