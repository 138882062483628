import 'bootstrap/dist/js/bootstrap.bundle'
// import '@fortawesome/fontawesome-free/css/all.css'
// to use fontawesome layers, masks:
import '@fortawesome/fontawesome-free/js/all.js'
import 'mobile/stylesheets/style'

import MainNav from '../plugins/main_nav.js'
import tinySlider from '../plugins/tiny_slider'
import Forms from '../plugins/forms.js'

import 'mobile/polymer/application.js'

require("jquery")

document.addEventListener('DOMContentLoaded', function(){
  MainNav.init();
  tinySlider.init()
  Forms.init()
  // console.log('you are in new mobile frontend!')

  document.getElementById('searchModal').addEventListener('shown.bs.modal', function () {
    document.getElementById('search-input-text').focus()
  })

  // [data-bs-toggle=multi-collapse]
  // [data-bs-target='.multi-collapse' <selector>]
  var collapseElementList = [].slice.call(document.querySelectorAll('[data-bs-toggle=multi-collapse]'))
  var collapseList = collapseElementList.map(function (collapseEl) {
    collapseEl.addEventListener('click', event => {
      event.preventDefault()
      document.querySelectorAll(collapseEl.getAttribute('data-bs-target')).forEach(function(container){
        container.classList.toggle('show')
      })
    })
  })

}, false);
