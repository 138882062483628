import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { ListBehavior } from '../../behaviors/list-behavior.js'
import { I18nBehavior } from '../../behaviors/i18n-behavior.js'
import { NewsListStyle } from './style.js';

export const NewsList = class extends mixinBehaviors([I18nBehavior, ListBehavior], PolymerElement) {
  static get is() { return 'news-list'; }
  static get behaviors() { return [I18nBehavior, ListBehavior] }
  static get properties() { return { type: {value: 'new'} } }
  static get template() {
    return html`
      ${NewsListStyle}
      <div class="news-featured-list">
        <template is="dom-if" if="{{featuredItem}}">
          <div class="featured-item featured-news-item">
            <a href$="{{featuredItem.url}}">
              <img src="{{featuredItem.img}}" id="featured_item_image" class="item-image featured-item-image news-item-image"></img>
              <div class="item-data news-item-data">
                <h2 class="item-title news-item-title">{{featuredItem.title}}</h2>
                <span class="item-date news-item-date">{{featuredItem.published_at}}</span>
                <template is="dom-if" if="{{featuredItem.comments_count != '0'}}">
                  <span class="item-comments news-item-comments">
                    <i class="fas fa-comment-alt fa-fw"></i>
                    <span>{{featuredItem.comments_count}}</span>
                  </span>
                </template>
              </div>
            </a>
          </div>
        </template>
        
        <div class="std-list news-list" id="list">
          <template is="dom-repeat" items="[[items]]">
            <div class="std-list-item news-list-item">
              <a href$="{{item.url}}">
                <div class="item-image news-item-image">
                  <img src="{{item.img}}" class="img-fluid"></img>
                </div>
                <div class="item-data news-item-data">
                  <h2 class="item-title news-item-title">{{item.title}}</h2>
                  <span class="item-date news-item-date">{{item.published_at}}</span>
                  <template is="dom-if" if="{{item.comments_count != '0'}}">
                    <span class="item-comments news-item-comments">
                      <i class="fas fa-comment-alt fa-fw"></i>
                      <span>{{item.comments_count}}</span>
                    </span>
                  </template>
                </div>
              </a>
            </div>
          </template>
        </div>

        <template is="dom-if" if="[[_availablePagination(paginate, items)]]">
          <button class="std-list-next-page btn btn-custom" on-click="getNextPage">
            <i class="fas fa-fw"></i>
            [[i18n('news_list.see_more')]]
          </button>
        </template>
      </div>
    `;
  }
  constructor() {
    super();
  }
}

customElements.get(NewsList.is) || customElements.define(NewsList.is, NewsList);
