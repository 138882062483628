import { html } from '@polymer/polymer/polymer-element.js'
import { SharedStyle } from '../shared-style.js';

export const QuestionsListStyle = html`
  ${SharedStyle}
  <style>
:host{

}
  </style>
`;
