import { html } from '@polymer/polymer/polymer-element.js'
import { SharedStyle } from '../shared-style.js';

export const ParticipationFabStyle = html`
  ${SharedStyle}
  <style>

.fab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  user-select: none;
  position: fixed;
  bottom: 30px; 
  right: 30px;
  padding: 0.5em 0;
  cursor: pointer;
}

.fab-container.create.proposal {
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
}

.fab-container.not-floating {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  opacity: 0.95;
} 

.fab-container .fab {
  position: relative;
  width: 60px;
  height: 60px;
  background-color: var(--dark-primary-color);
  border-radius: 50%;
  border: 1px solid var(--dark-primary-color);
  z-index: 2;
  box-shadow: var(--box-shadow-lg);
}

.fab-container.not-floating .fab {
  box-shadow: var(--box-shadow);
  padding: 0.2rem 0.4rem;
  margin-bottom: 0.25rem;
  width: 40px;
  height: 40px;
}

.fab-container.vote_up {
  right: 75%;
}

.fab-container.not-floating.vote_up {
  right: 0;
}

.fab-container.vote_down {
  right: 40%
}

.fab-container.vote_up .fab {
  background-color: var(--in-favor-color);
  border-color: var(--in-favor-color);
}

.fab-container.vote_down .fab {
  background-color: var(--against-color);
  border-color: var(--against-color);
}

.fab-container .fab .fab-content {
  display: flex;
  align-items: center; 
  justify-content: center;
  height: 100%; 
  width: 100%;
  border-radius: 50%;
  color: var(--light);
}

.fab-container .fab .fab-content i {
  font-size: 1.9rem;
}

.fab-container.not-floating .fab .fab-content i {
  font-size: 1em;
}

.fab-container .fab .fab-content .fab-text {
  font: var(--h2-font);
  font-weight: 600;
  margin-left: 0.25em;
}

.fab-container.not-floating .fab .fab-content .fab-text {
  font: var(--h3-font);
  font-weight: 600;
}

.fab-container.create .fab {
  width: 100%;
  height: auto;
  background-color: var(--citizen-color);
  border-color: var(--citizen-color);
  opacity: 0.9;
  text-transform: uppercase;
  border-radius: 50rem;
}

.fab-container.create .fab .fab-content {
  justify-content: center;
  border-radius: 50rem;
  padding: .25rem 0;
}

.fab-container.not-floating.create .fab .fab-content {
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

.fab-container.disabled {
  cursor: not-allowed;
}

.fab-container.disabled .fab {
  background-color: var(--secondary);
  border-color: var(--secondary);
  cursor: not-allowed;
}

.fab-container.disabled .fab i {
  color: var(--light-secondary);
}

.fab-container.done .fab {
  background-color: var(--light);
  border-width: 0.2em;
}

.fab-container.done .fab .fab-content i {
  color: var(--success);
}

.fab-container .sub-button {
  position: absolute;
  display: flex;
  align-items: center; 
  justify-content: center;
  bottom: 10px; 
  right: 20px;
  height: 50px; 
  width: 50px;
  background-color: var(--dark-primary-color);
  border-radius: 50%;
  transition: all .3s ease;
}
.fab-container .sub-button:hover {
  cursor: pointer;
}
.fab-container .sub-button i {
  color: white;
  padding-top: 6px;
}

.fab-container:hover,
.fab-container:focus,
.fab-container:active {
  opacity: 1;  
}
.fab-container:hover .sub-button:nth-child(2) {
  transform: translateY(-80px);
}
.fab-container:hover .sub-button:nth-child(3) {
    transform: translateY(-140px);
}
.fab-container:hover .sub-button:nth-child(4) {
    transform: translateY(-200px);
}
.fab-container:hover .sub-button:nth-child(5) {
    transform: translateY(-260px);
}
.fab-container:hover .sub-button:nth-child(6) {
    transform: translateY(-320px);
}

  </style>
`;
