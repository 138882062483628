import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { ListBehavior } from '../../behaviors/list-behavior.js'
import { I18nBehavior } from '../../behaviors/i18n-behavior.js'
import { EventsListStyle } from './style.js';

export const EventsList = class extends mixinBehaviors([I18nBehavior, ListBehavior], PolymerElement) {
  static get is() { return 'events-list'; }
  static get behaviors() { return [I18nBehavior, ListBehavior] }
  static get properties() { return { type: {value: 'event'} } }
  static get template() {
    return html`
      ${EventsListStyle}
      <div class="std-list events-list" id="list" >
        <template is="dom-repeat" items="[[items]]">
          <div class$="[[itemClass(type, item)]]" id$="[[type]]_[[item.id]]">
            <a href$="[[item.url]]">
              <div class="event-list-item-date">
                <div class="event-list-item-date-wrap">
                  <span class="events-day">[[item.day]]</span>
                  <span class="events-month">[[item.month]]</span>
                  <span class="events-year">[[item.year]]</span>
                </div>
                <template is="dom-if" if="[[item.public_contracting]]">
                  <div class="event-public-contracting">
                    <img src$="[[item.public_contracting.path]]" alt$="[[item.public_contracting.alt]]" class="img-fluid"></img>
                  </div>
                </template>
              </div>
              <div class="item-data event-item-data">
                <div class$="[[item.status]]">
                  <div class="first-line">
                    <span class="event-time">[[item.hours]]</span>
                  </div>
                  <h2 class='item-title'>[[item.title]]</h2>
                  <template is="dom-if" if="[[item.streaming_text]]">
                    <div class="streaming-info">
                      <i class="fas"></i>
                      <span class$="event-[[item.status]]-text" inner-h-t-m-l="[[item.streaming_text]]"></span>
                    </div>
                  </template>
                </div>
              </div>
            </a>
          </div>
        </template>
        <template is="dom-if" if="[[_availablePagination(paginate, items)]]">
          <button class="std-list-next-page btn btn-custom" on-click="getNextPage">
            <i class="fas fa-fw"></i>
            [[i18n('events_list.see_more')]]
          </button>
        </template>
      </div>
    `;
  }
}

customElements.get(EventsList.is) || customElements.define(EventsList.is, EventsList);
