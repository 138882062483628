//import '@fortawesome/fontawesome-free/css/all.css'
import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { I18nBehavior } from '../../behaviors/i18n-behavior.js'
import { ParticipationBehavior } from '../../behaviors/participation-behavior.js'
import { ParticipationFabStyle } from './style.js';

export const ParticipationFab = class extends mixinBehaviors([I18nBehavior, ParticipationBehavior], PolymerElement) {
  static get is() { return 'participation-fab'; }
  static get behaviors() { return [I18nBehavior, ParticipationBehavior] }
  static get properties() { return { } }
  static get template() {
    return html`
      ${ParticipationFabStyle}
      <div class$="fab-container [[fabClass]]" id="fab" on-tap="fabTap" title$="[[fabTitle]]">
        <div class="fab shadow">
          <div class="fab-content">
            <i class$="fas fa-[[icon]]"></i>
            <template is="dom-if" if="[[showFabTitle]]">
              <span class="fab-text">[[fabTitle]]</span>
            </template>
          </div>
        </div>
      </div>      
    `;
  }
  constructor() {
    super();    
  }
}

// sub-button on hover
// <div class="sub-button shadow">
//   <a href="google.com" target="_blank">
//     <i class="fas fa-plus"></i>
//   </a>
// </div>
// <div class="sub-button shadow">
//   <a href="google.com" target="_blank">
//     <i class="fas fa-envelope"></i>
//   </a>
// </div>
// <div class="sub-button shadow">
//   <a href="google.com" target="_blank">
//     <i class="fas fa-bomb"></i>
//   </a>
// </div>
// <div class="sub-button shadow">
//   <a href="google.com" target="_blank">
//     <i class="fas fa-book-dead"></i>
//   </a>
// </div>

customElements.get(ParticipationFab.is) || customElements.define(ParticipationFab.is, ParticipationFab);
