import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { ListBehavior } from '../../behaviors/list-behavior.js'
import { I18nBehavior } from '../../behaviors/i18n-behavior.js'
import { QuestionsListStyle } from './style.js';

export const QuestionsList = class extends mixinBehaviors([I18nBehavior, ListBehavior], PolymerElement) {
  static get is() { return 'questions-list'; }
  static get behaviors() { return [I18nBehavior, ListBehavior] }
  static get properties() { 
    return {
      type: {
        value: 'question'
      },
      page: {
        value: 1,
        notify: true
      },
      logged: {
        type: Boolean,
        value: false
      },
      participation: {
        type: Boolean,
        value: false
      },
      loginPath: {
        type: String,
        value: ""
      },
    } 
  }
  static get template() {
    return html`
      ${QuestionsListStyle}
      <div class="std-list questions-list">
        <template is="dom-repeat" items="[[items]]">
          <div class$="[[itemClass(type, item)]]" id$="[[type]]_[[item.id]]">
            <question-item item="[[item]]" logged="[[logged]]" participation="[[participation]]" login-path="[[loginPath]]"></question-item>
          </div>
        </template>

        <template is="dom-if" if="[[_availablePagination(paginate, items)]]">
          <button class="std-list-next-page btn btn-custom" on-click="getNextPage">
            <i class="fas fa-fw"></i>
            [[i18n('questions_list.see_more')]]
          </button>
        </template>
      </div>
    `;
  }
  constructor() {
    super();
  }
}

customElements.get(QuestionsList.is) || customElements.define(QuestionsList.is, QuestionsList);
