import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { ListBehavior } from '../../behaviors/list-behavior.js'
import { I18nBehavior } from '../../behaviors/i18n-behavior.js'
import { ProposalsListStyle } from './style.js'

export const ProposalsList = class extends mixinBehaviors([I18nBehavior, ListBehavior], PolymerElement) {
  static get is() { return 'proposals-list'; }
  static get behaviors() { return [ I18nBehavior, ListBehavior ] }
  static get properties() { return { type: {value: 'proposal'} } }
  static get template() {
    return html`
      ${ProposalsListStyle}
      <div class="std-list proposals-list" id="list">
        <template is="dom-repeat" items="[[items]]">
          <div class$="[[itemClass(type, item)]]" id$="[[type]]_[[item.id]]">
            <a href$="{{item.url}}">
              <div class="item-image proposal-image">
                <div class="proposal-item-result-answer">
                  <div class$="proposal-item-result [[item.result_class]]" inner-h-t-m-l="[[item.result]]"></div>
                  <span class="item-answer proposal-item-answer" inner-h-t-m-l="{{item.answer}}"></span>
                </div>
              </div>
              <div class="item-data proposal-item-data">
                <h2 class="item-title proposal-item-title">[[item.title]]</h2>
                <span class="item-author proposal-item-author">[[item.author]]</span>
                <span class="item-date proposal-item-date">[[item.created_at]]</span>
                <br />
                <span class="item-participation proposal-item-participation"><span class$="[[item.result_class]]" inner-h-t-m-l="{{item.participation}}"></span></span>
                <template is="dom-if" if="[[item.comments_count]]">
                  <span class="item-comments proposal-item-comments">
                    <i class="fas fa-comment-alt fa-fw"></i>
                    <span>[[item.comments_count]]</span>
                  </span>
                </template>
              </div>
            </a>
          </div>
        </template>
        <template is="dom-if" if="[[_availablePagination(paginate, items)]]">
          <button class="std-list-next-page btn btn-custom" on-click="getNextPage">
            <i class="fas fa-fw"></i>
            [[i18n('proposals_list.see_more')]]
          </button>
        </template>
      </div>
    `;
  }
  constructor() {
    super();
  }
}

customElements.get(ProposalsList.is) || customElements.define(ProposalsList.is, ProposalsList);
