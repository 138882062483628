import { html } from '@polymer/polymer/polymer-element.js'
import { SharedStyle } from '../shared-style.js';

export const DepartmentsListStyle = html`
  ${SharedStyle}
  <style>
.department-list-item {
  border-bottom: 1px solid var(--light-secondary);
  align-items: center;
  padding: 0.4em 0 !important;
}

.department-list-item .department-item-image {
  width: max(8%, 2vw) !important;
  padding: 0.25rem;
  padding-left: 1rem
}

.department-list-item .item-data.department-item-data {
  width: auto !important;
}

.department-list-item h2.item-title {
  font: var(--h1-font) !important;
  font-weight: 600 !important;
  margin: 0.5em 0.25em !important;
  text-decoration: underline;
}

.department-list-item .department-statistics-list {
  padding-left: 0;
  list-style-type: none;
  margin: 0.25rem;
}

.department-list-item .department-statistics-list li {
  font: var(--h3-font);
  text-transform: uppercase;
  font-weight: 600;
  color: var(--text-color);
  padding: .5rem 1.5rem;
}

.department-list-item .department-statistics-list li span.proposals_count {
  margin-left: .5rem;
  color: var(--citizen-color);
}

.department-list-item .department-statistics-list li span.debates_count {
  margin-left: .5rem;
  color: var(--gov-color);
}
.std-list .std-list-item.department-list-item a {
  gap: 1em;
  justify-content: flex-start;
  align-items: center;
}
  </style>

`;
