import { I18nBehavior } from './i18n-behavior.js';
import xhr_headers from '../../../plugins/xhr_headers.js'

export const ListBehavior = {
  properties: {
    featuredItem: Object,
    items: {
      type: Array,
      value: []
    },
    total: {
      type: Number
    },
    opened: {
      type: Boolean, 
      default: false
    },
    paginate: {
      type: Boolean, 
      value: false
    },
    paginateParams: {
      type: String,
      value: ""
    },
    page: {
      value: 1,
      notify: true
    },
    type: {
      type: String, 
      value: 'debate'
    },
    nextPageUrl: {
      computed: '_getNextPageUrl(type, page, paginateParams)'
    }
  },
  _getNextPageUrl: function(type, page, paginateParams) {
    return `/mobile/${I18nBehavior.getLocale()}/${type}s.json?page=${this.page}&${paginateParams}` 
  },
  _availablePagination: function(paginate, items) {
    return paginate && ((this.total != undefined && items.length < this.total) || this.total === undefined);
  },
  itemClass: function(type, item){
    var klass = `std-list-item ${type}-list-item`
    if (item.is_official) {
      klass += ' is-official'
    }
    return klass;
  },
  getNextPage: function(){
    this.classList.add('loading')
    this.page = this.page + 1;
    fetch(this.nextPageUrl, { headers: xhr_headers })
    .then(response => response.json())
    .then((data) => {
      this.classList.remove('loading')
      if (data.length > 0) {
        this.items = this.items.concat(data);
      } else {
        this.paginate = false;
      }
    })
  }
};
