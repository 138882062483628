import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { ListBehavior } from '../../behaviors/list-behavior.js'
import { DepartmentsListStyle } from './style.js';

export const DepartmentsList = class extends mixinBehaviors([ListBehavior], PolymerElement) {
  static get is() { return 'departments-list'; }
  static get properties() { return { type: {value: 'department'} } }
  static get behaviors() { return [ ListBehavior ] }
  static get template() {
    return html`
      ${DepartmentsListStyle}
      <div class="std-list departments-list">
        <template is="dom-repeat" items="[[items]]">
          <div class$="[[itemClass(type, item)]]" id$="[[type]]_[[item.id]]">
            <a href$="[[item.url]]">
              <template is="dom-if" if="[[item.img]]">
                <div class="item-image department-item-image">
                  <img src$="[[item.img]]" class="img-fluid"></img>
                </div>
              </template>
              <div class="item-data department-item-data">
                <h2 class="item-title department-title">[[item.title]]</h2>
                <template is="dom-if" if="[[item.proposals_count]]">
                  <div class="department-statistics">
                    <ul class="department-statistics-list">
                      <li class>
                        <span>[[item.proposals_count_text]]</span>
                        <span class="number proposals_count">[[item.proposals_count]]</span>
                      </li>
                      <li>
                        <span>[[item.debates_count_text]]</span>
                        <span class="number debates_count">[[item.debates_count]]</span>
                      </li>
                    </ul>
                  </div>
                </template>
              </div>
            </a> 
          </div>
        </template>
      </div>
    `;
  }
}

customElements.get(DepartmentsList.is) || customElements.define(DepartmentsList.is, DepartmentsList);
