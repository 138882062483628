import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { ListBehavior } from '../../behaviors/list-behavior.js'
import { CommentsListStyle } from './style.js'

export const CommentsList = class extends mixinBehaviors([ListBehavior], PolymerElement) {
  static get is() { return 'comments-list'; }
  static get behaviors() { return [ ListBehavior ] }
  static get properties() { return { type: {value: 'comment'} } }
  static get template() {
    return html`
      ${CommentsListStyle}
      <div class$="std-list [[type]]s-list">
        <template is="dom-repeat" items="[[items]]">
          <div class$="[[itemClass(type, item)]]" id$="[[type]]_[[item.id]]">
            <div class="item-data comment-item-data">
              <div class="item-author comment-item-author">[[item.author]]</div>
              <div class='item-date comment-item-date'>[[item.created_at]]</div>
              <div class="item-text comment-item-text">[[item.text]]</div>
            </div>
          </div>
        </template>
      </div>
    `;
  }
}

customElements.get(CommentsList.is) || customElements.define(CommentsList.is, CommentsList);
