import { I18nBehavior } from './i18n-behavior.js'
import xhr_headers from '../../../plugins/xhr_headers.js'
import { Tooltip } from 'bootstrap';

export const ParticipationBehavior = {
  properties: {
    logged: {
      type: Boolean,
      value: false
    },
    remote: {
      type: Boolean,
      value: false
    },
    disabled: {
      type: Boolean
    },
    loginPath: {
      type: String,
      value: ""
    },
    participationPath: {
      type: String,
      value: ""
    },
    type: {
      type: String,
      value: "comment"
    },
    done: {
      type: Boolean,
      value: false
    },
    position: {
      type: String,
      value: 'float'
    },
    icon: {
      computed: '_getIcon(type, done)'
    },
    fabClass: {
      computed: '_getFabClass(type, resource, done, position)'
    },
    fabTitle: {
      computed: '_getFabTitle(type, resource, done)'
    },
    showFabTitle: {
      computed: '_shouldShowFabText(type)'
    },
    resource: {
      type: String,
      value: ""
    },
    // counter: {
    //   type: Number,
    //   notify: true
    // }
  },
  attached: function(){
    if (this.$.fab) {
      if (!this.disabled) {
        this.$.fab.disabled = false;
        this.$.fab.classList.remove('disabled')
      } else {
        this.$.fab.disabled = true;
        this.$.fab.classList.add('disabled')
      }
    }
    // this.addEventListener('enableParticipation', this._enableParticipation);
  },
  _getTypeIcon: function(type){
    let icon = new String;
    switch (type) {
      case "vote_up":
        icon = "thumbs-up";
        break;
      case "vote_down":
        icon = "thumbs-down";
        break;
      case "create":
      case "question":
        icon = "plus";
        break;
      default:
        icon = 'comment-alt'
        break;
    }
    return icon;
  },
  _getIcon: function(type, done){
    return this._getTypeIcon(type)
  },
  _getFabClass: function(type, resource, done, position){
    let klassName = (type + ' ' + resource)
    if (position != 'float') {
      klassName += ' not-floating'
    }
    if (done) {
      klassName += ' done'
    }
    return klassName;
  },
  _getFabTitle: function(type, resource, done){
    let title = [`${(done ? 'un' : '')}${type}`, resource].filter(n => n).join('_');
    return I18nBehavior.i18n('participation_fab.' + title)
  },
  _shouldShowFabText: function(type){
    // TOREVIEW: this is participation-button, not participation-fab
    return (type == 'create')
  },
  fabTap: function(){
    if (!this.disabled) {
      if (this.logged) {
        if (this.remote) {
          let remote_method = (this.done ? 'delete' : 'get')
          // let questionItem = this.parentElement.parentElement
          // let counter = questionItem.que
          fetch(this.participationPath, {
            method: remote_method,
            headers: xhr_headers
          // }).then(response => response.json()).then((data) => {
          }).then((data) => {
            this.done = !this.done
            this.disabled != this.disabled
            // this.counter = this.counter + (this.done ? +1 : -1)
            // questionItem.$.counter.dispatchEvent(new CustomEvent('updateParticipationCounter', {'detail': increment}));
            // questionItem.item.already_voted = true
            // resolve()
            let tooltipTitle = I18nBehavior.i18n(`participation_fab.vote_${(this.done ? 'saved' : 'destroyed')}`)
            this.showTooltip(tooltipTitle)
          }).catch((err) => {
            this.showTooltip(I18nBehavior.i18n('participation_fab.remote_error'))
            // resolve()
          });
        } else {
          this.redirectToAction(this.participationPath)
        }        
      } else {
        this.redirectToAction(this.loginPath + '?after_login=' + encodeURIComponent(this.participationPath) + '&login_cancel=' + encodeURIComponent(window.location.pathname));
      }
    }
  },
  // _enableParticipation: function(){
  //   if (this.item.already_voted) {
  //     // decrement siblings participationCounter
  //     this.dispatchEvent(new CustomEvent('updateParticipationCounter', {'detail': -1}))
  //     this.item.already_voted = false
  //   }
  //   this.done = false
  //   this.disabled = false
  //   // TOREVIEW
  // },
  redirectToAction: function(newLocation){
    window.location = newLocation
  },
  showTooltip(title){
    let tooltip = new Tooltip(this, {title: title, customClass: 'fab-tooltip'})
    tooltip.show()
    setTimeout(function(){ tooltip.hide();tooltip.disable() }, 2000);
  }
};
