import { html } from '@polymer/polymer/polymer-element.js'
import { SharedStyle } from '../shared-style.js';

export const EventsListStyle = html`
  ${SharedStyle}
  <style>
  
:host {
  padding: 0;
  display: block;
  @apply(--events-list);

}
     
.event-list-item {
  align-items: flex-start;
  padding: 0;
  color: var(--text-color);
}

.event-list-item h2 {
  line-height: 1.2em;
  margin: 0;
  padding: 0;
  clear: both;
}

.event-list-item span.event-time {
  font: var(--meta-text-font);
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: block;
}

.nested-event {
  padding: 15px 0;
  border-bottom: 1px solid var(--light-secondary);
}

.nested-event:first-of-type {
  padding-top: 0;
}

.nested-event:last-child {
  border-bottom: none;
}

.event-list-item-date {
  width: 22% !important;
  display: inline-block;
  align-self: start;
  justify-content: center;
  color: var(--secondary);
  float: left;
}

.event-item-data {
  width: 76% !important;
}

.event-list-item-date .event-list-item-date-wrap {
  align-self: flex-start;
  padding-top: 0.25rem;
}

.event-list-item-date .events-day {
  clear: both;
  display: block;
  text-align: center;
  font: var(--h1-font);
  font-size: 220%;
  line-height: 1.2;
  color: var(--primary);
}

.event-list-item-date .events-month {
  clear: both;
  display: block;
  text-align: center;
  font: var(--meta-text-font);
  font-size: 85%;
  text-transform: capitalize;
  overflow-x: hidden;
}

.event-list-item-date .events-year {
  clear: both;
  display: block;
  text-align: center;
  font: var(--meta-text-font);
  font-size: 90%;
}

.event-item-data, .event-item-data .first-line {
  color: var(--text-color);
  display: flex;
  align-items: center;
}

.event-item-data > .live,
.event-item-data > .programmed,
.event-item-data > .announced,
.event-item-data > .passed,
.event-item-data > .future {
  padding: 0 10px;
  background-color: transparent;
  width: 100%;
}

.event-item-data > .passed {
  padding: 0;
}

.event-item-data > .passed h2 {
  color: var(--secondary);
}

.event-item-data > .passed .streaming-info {
  opacity: 0.8;
}

.event-item-data .streaming-info {
  margin-top: 0.75rem;
  display: flex;
  padding: .5rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.3;
  text-transform: uppercase;
  color: var(--light);
  background-color: var(--secondary);
  border-color: var(--secondary);
  border-radius: .2rem;
}

.event-item-data .streaming-info .info_and_link {
  display: flex;
  justify-content: space-between;
}

.event-item-data .streaming-info .info_and_link a {
  text-decoration: underline;
  color: var(--light);
}

.event-item-data > .programmed .streaming-info,
.event-item-data > .future .streaming-info {
  background-color: var(--primary);
  border-color: var(--primary);
}

.event-item-data > .live .streaming-info {
  background-color: var(--success);
  border-color: var(--success);
}

.event-item-data > .announced .streaming-info {
  background-color: var(--lighter-primary);
  border-color: var(--lighter-primary);
}

.event-item-data i {
  margin-right: 0.3rem;
  margin-top: .1rem;
}

.event-item-data .live i::before {
  content: "\\f519";
}

.event-item-data .programmed i::before,
.event-item-data .announced i::before,
.event-item-data .future i::before {
  content: "\\f144";
}

.event-public-contracting {
  text-align: center;
  margin-top: 0.75rem;
}
</style>
`;
