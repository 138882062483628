import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { ListBehavior } from '../../behaviors/list-behavior.js'
import { PoliticiansListStyle } from './style.js';

export const PoliticiansList = class extends mixinBehaviors([ListBehavior], PolymerElement) {
  static get is() { return 'politicians-list'; }
  static get properties() { 
    return { 
      logo: String,
      type: {value: 'politician'}
    }
  }
  static get behaviors() { return [ ListBehavior ] }
  static get template() {
    return html`
      ${PoliticiansListStyle}
      <div class="std-list politicians-list">
        <template is="dom-repeat" items="[[items]]">
          <div class$="[[itemClass(type, item)]]" id$="[[type]]_[[item.id]]">
            <a href$="[[item.url]]">
              <div class="politician-item">
                <div class="politician-item-info">
                  <div class="politician-item-image">
                    <img src$="[[item.img]]" class="photo-image" id$="image-[[item.id]]" style="display: block"></img>
                    <img src$="[[item.personal_sign_url]]" class="personal-sign-image" id$="personal-sign-gif-[[item.id]]" style="display: none"></img>
                  </div>
                  <div class="politician-item-data">
                    <h2 class="item-title politician-item-title">[[item.public_name]]</h2>
                    <div class="item-role politician-item-role">[[item.public_role]]</div>
                  </div>
                </div>
                <div class$="politician-personal-sign [[showPersonalSignToggle(item.personal_sign_url)]]">
                  <button type='button' on-tap="togglePersonalSign" aria-expanded="false" aria-controls$="image-[[item.id]] personal-sign-gif-[[item.id]]">
                    <img src$="[[logo]]" id$="[[item.id]]"></img>
                  </button>
                </div>
              </div>
            </a>
          </div>
        </template>
      </div>
    `;
  }
  togglePersonalSign(event){
    event.preventDefault()
    var id = event.target.getAttribute('id')
    var image = this.$$('#image-' + id);
    var personalSign = this.$$('#personal-sign-gif-' + id);
    if (image.style.display === 'block') {
      image.style.display = 'none'
      personalSign.style.display = 'block'
    } else {
      image.style.display = 'block'
      personalSign.style.display = 'none'
    }
  }
  showPersonalSignToggle(url){
    return (url.length > 0) ? 'on' : 'off';
  }
  constructor() {
    super();
  }
}

customElements.get(PoliticiansList.is) || customElements.define(PoliticiansList.is, PoliticiansList);
