require('@webcomponents/webcomponentsjs/webcomponents-bundle.js')
import { Polymer } from '@polymer/polymer/polymer-legacy.js';

import '@fortawesome/fontawesome-free/css/all.css'

import 'mobile/polymer/components/share-button'
import 'mobile/polymer/components/comments-list'
import 'mobile/polymer/components/debates-list'
import 'mobile/polymer/components/departments-list'
import 'mobile/polymer/components/events-list'
import 'mobile/polymer/components/news-list'
import 'mobile/polymer/components/participation-fab'
import 'mobile/polymer/components/politicians-list'
import 'mobile/polymer/components/proposals-list'
import 'mobile/polymer/components/question-item'
import 'mobile/polymer/components/questions-list'
import 'mobile/polymer/components/surveys-list'
