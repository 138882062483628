import { html } from '@polymer/polymer/polymer-element.js'
import { SharedStyle } from '../shared-style.js';

export const QuestionItemStyle = html`
  ${SharedStyle}
  <style>

  :host(.active) {
    box-shadow: 0 0 0.25em .75em var(--light-info), 0 0 0.25em .5em var(--light-info), inset 30em -.5em .5em var(--light-info);
  }

  .question-item {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  .question-item .question-item-data {
    width: auto;
  }

  .question-item .question-item-actions {
    width: 20%;
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .question-item .question-item-actions share-button {
    opacity: .8;
  }
  .question-item .question-item-actions participation-fab {
    
  }

  .question-item .question-item-actions .question-item-votes {
    font: var(--global-text-font);
    font-weight: 600;
    color: var(--text-color);
  }
  </style>
`;
