import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/dist/tiny-slider.css'

const tinySlider = {
  init(){
    document.querySelectorAll('.slider').forEach((slider) => {
      let id = slider.getAttribute('id')
      let items = parseFloat(slider.getAttribute('data-slider-items') || 1)
      let default_options = {
          container: `#${id}`,
          nav: false,
          navAsThumbnails: true,
          items: items,
          // slideBy: 1,
          mouseDrag: true,
          gutter: 10,
          swipeAngle: false,
          // responsive: { 992: { items: (items + 0.1) } },
          }

      let controlsContainerId = `${id}_controls`
      let navContainerId = `${id}_thumbnails`

      let controls_options = {}

      if (slider.getAttribute('data-slider-nav') && document.getElementById(navContainerId)) {
        controls_options.nav = true
        controls_options.navContainer = `#${navContainerId}`
        controls_options.navAsThumbnails = true
      }
      if (document.getElementById(controlsContainerId)) {
        controls_options.controlsContainer = `#${controlsContainerId}`
        controls_options.prevButton = `#${controlsContainerId} .slider-control-prev`
        controls_options.nextButton = `#${controlsContainerId} .slider-control-next`
      }
      // console.log(controls_options)
      tns({...default_options, ...controls_options })
    })
  }
}

export default tinySlider
