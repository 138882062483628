import { html } from '@polymer/polymer/polymer-element.js'

// --gov-color:             #47bfea;
export const SharedStyle = html`
  <style>
:root {
  --primary:               #005b8c;
  --light-primary:         #1d79b0;
  --lighter-primary:       #4a94c0;
  --dark-primary:          #004970;

  --secondary:             #6c757d;
  --light-secondary:       #dddddd;
  --lighter-secondary:     #eeeeee;

  --light:                 #fff;
  --dark:                  #000;
  --success:               #007a00;
  --light-info:            #d9e6ee;

  --text-color:            #555555;
  --secondary-text-color:  #999999;
  --disabled-text-color:   #aaaaaa;

  --citizen-color:         #5f972f;
  --gov-color:             #127EA5;
  --against-color:         #cc3333;
  --in-favor-color:        #297A29;
  --neutral-color:         #465866;

  --global-font-family:    Arial, sans-serif;
  --special-font-family:   'Open Sans', sans-serif;
  --global-size:           16px;

  --global-text-font:                normal 14px/20px var(--global-font-family);
  --meta-text-font:                  normal 12px/16px var(--global-font-family);
  --detail-event-info-font:          bold 16px var(--global-font-family);

  --h0-font:                         700 24px/28px var(--special-font-family);
  --h1-font:                         700 20px/24px var(--special-font-family);
  --h2-font:                         700 18px/20px var(--special-font-family);
  --h3-font:                         700 16px/18px var(--special-font-family);

  --box-shadow:                      0.2rem .5rem 1rem rgb(0 0 0 / 15%);
  --box-shadow-lg:                   0.2rem .5rem .5rem rgb(0 0 0/ 35%);
}

:host {
  display: block;
}

@-webkit-keyframes fa-spin {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

h2.item-title {
  font: var(--h2-font);
  color: var(--dark-primary-color);
}

a {
  text-decoration: none;
}

.std-list {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.std-list .std-list-item {
  font: var(--h3-font);
  border-bottom: 1px solid var(--light-secondary);
  padding: 1em .1em;
}

.std-list .std-list-item.is-official {
  background-color: var(--light-info);
  border-color: var(--dark-primary);
  padding: 1em .25em;
}

.item-text {
  font: var(--global-text-font);
  padding: 0.5rem 0.1rem;
  color: var(--text-color);
  margin: 0;
  margin-bottom: 5px;
}

.item-author {
  font: var(--detail-event-info-font);
  color: var(--primary);
  display: block;
}

.item-author.proposal-item-author, .item-date.proposal-item-date {
  font: var(--h3-font);
  color: var(--text-color);
  font-weight: 600;
  font-size: 75%;
  display: inline;
}

.item-author.proposal-item-author:after {
  content: ', ';
}

.item-date {
  font: var(--meta-text-font);
  color: var(--secondary);
  display: inline-block;
}

.item-participation {
  font: var(--meta-text-font);
  color: var(--text-color);
  display: inline-block;
}

.item-participation .in_favor {
  color: var(--in-favor-color);
}

.item-participation .against {
  color: var(--against-color);
}

.item-participation span.percentage_text{
  display: inline-block;
  padding-right: 5px;
  text-align: center;
  border-right: 1px solid var(--light-secondary);
}
.item-participation span.participation{
  display: inline-block;
  padding-left: .25rem;
  padding-right: .25rem;
  text-align: center;
  border-right: 1px solid var(--light-secondary);
  color: var(--secondary) !important;
}

.item-participation span.participation.against {
  color: var(--against-color);
}
.item-participation span.participation.against .participation {
  color: var(--text-color);
}

.item-participation span.participation.neutral {
  color: var(--neutral-color);
}
.item-participation span.participation.neutral .participation{
  color: var(--text-color);
}

.item-participation span.participation.in_favor {
  color: var(--in-favor-color);
}
.item-participation span.participation.in_favor .participation {
  color: var(--text-color);
}

.item-participation .result{
  display: inline-block;
}
.item-participation .result.against{
  color: var(--against-color);
}
.item-participation .result..neutral{
  color: var(--neutral-color);
}
.item-participation .result.in_favor{
  color: var(--in-favor-color);
}
.item-participation .result .participation{
  color: var(--text-color) !important;
}

.std-list .std-list-item a {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.std-list .std-list-item a > div.item-image {
  width: 24%;
}

.std-list .std-list-item a > div.item-data {
  width: 73%;
}

.std-list .std-list-item a > div.item-data.proposal-item-data {
  width: 72%;
}

.std-list .std-list-item a > div.item-data h2.item-title {
  margin: 0.25rem 0rem .5rem;
  font: var(--h3-font);
}

.featured-item {
  border-bottom: 1px solid var(--light-secondary);
  padding-bottom: 0.5rem;
  margin-bottom: 0.25rem;
}

.featured-item .featured-item-image {
  width: 100%;
  height: auto;
}

.featured-item h2.item-title {
  font: var(--h1-font);
  margin: 0.25rem 0rem;
}

div.item-image img {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 0.25em;
}

.proposal-item-result-answer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proposal-item-result-answer .proposal-item-result {
  width: 4.5em;
  height: 4.5em;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--light);
  border: 1px solid transparent;
  padding: 5%;
  margin-top: 0.2rem;
  text-align: center;
  font-size: .65rem;
  line-height: 1.2;
  font-weight: 600;
}

.proposal-item-result-answer .proposal-item-result.against {
  background-color: var(--against-color);
  border-color: var(--against-color);
}
.proposal-item-result-answer .proposal-item-result.neutral {
  background-color: var(--neutral-color);
  border-color: var(--neutral-color);
}
.proposal-item-result-answer .proposal-item-result.in_favor {
  background-color: var(--in-favor-color);
  border-color: var(--in-favor-color);
}

.proposal-item-result-answer .proposal-item-result .percentage_text {
  white-space: nowrap;
}

.proposal-item-result-answer .item-answer {
  font: var(--meta-text-font);
  color: var(--text-color);
  display: block;
  text-align: center;
  margin: 0.5em 0;
  line-height: 1.2;
}

.proposal-item-result-answer .item-answer a.answered {
  text-decoration: underline;
  color: var(--text-color);
}

.item-comments {
  font: var(--meta-text-font);
  padding-top: 7px;
  display: inline-block;
}

.item-comments i {
  color: #999;
  margin-left: 0.25rem;
}

.item-comments span {
  color: var(--text-color);
}

.item-date + .item-comments {
  border-left: 1px solid var(--light-secondary);
  margin-left: 0.5rem;
  padding-left: 0.5rem;
}

.btn {
  margin: .5em 0;
  width: 100%;
  padding: 3% 5%;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font: var(--h1-font);
  background-color: var(--primary);
  color: var(--light);
  border: 1px solid var(--primary);
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
  outline-width: 0;
  user-select: none;
  cursor: pointer;
  z-index: 0;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
}

.btn-custom {
  font-size: 95%;
  padding: 3%;
}

button.std-list-next-page {
  margin-top: 1.5rem;
}

button.std-list-next-page i.fas:before {
  content: '\\f101';
}

i.fas {
  font-style: normal;
  font-family: 'Font Awesome 6 Free' !important;
  font-weight: 900;
}

i.fas.fa-fw {
  margin-right: 0.25rem;
}

i.fas.fa-comment-alt {
  transform: scale(-1, 1);
}  

i.fas.fa-comment-alt:before {
  content: '\\f27a';
}

i.fas.fa-thumbs-up:before {
  content: '\\f164';
}

i.fas.fa-thumbs-down:before {
  content: '\\f165';
}

i.fas.fa-plus:before {
  content: '\\2b';
}

i.fas.fa-angle-double-right:before {
  content: '\\f101';
}

i.fas.fa-spinner::before {
  content: '\\f110';
}

i.fas.fa-user-edit::before {
  content: '\\f4ff';
}

i.fas.fa-pulse {
  animation: fa-spin 1s steps(8) infinite;
}

i.fas.fa-spin {
  animation: fa-spin 2s linear infinite;
}

:host(.loading) button.std-list-next-page {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

:host(.loading) button.std-list-next-page i.fas {
  animation: fa-spin 2s linear infinite;
}

:host(.loading) button.std-list-next-page i.fas:before {
  content: '\\f110';
}
  </style>

`;
