/* Navigation related functions */
let MainNav = {
  init() {
    document.getElementById("js-mainNavTrigger").addEventListener("click", function(event) {
      // Change main menu level 1 links to open level2 menu on click
      // level2 links are not hidden on click. 
      var dropdownElementList = [].slice.call(document.querySelectorAll('.js-mobile-nav'));
      dropdownElementList.map(function (dropdownToggleEl) {
        dropdownToggleEl.dataset["bsToggle"] = "dropdown";
        dropdownToggleEl.addEventListener('hide.bs.dropdown', function (e) {
          if (typeof e.clickEvent !== 'undefined') {
            if (e.clickEvent.target.parentElement.classList.contains('dropright-item')) {
              return e.preventDefault();
            }
          }
        });
      });
      document.getElementById("js-mainNav").classList.remove("exit-nav");
      document.getElementById("js-mainNav").classList.toggle("show");
      document.getElementsByTagName("body")[0].classList.toggle("offcanvas-active");
    });

    document.getElementById("js-closeMainNav").addEventListener("click", function(event) {
      document.getElementById("js-mainNav").classList.remove("show");
      document.getElementById("js-mainNav").classList.add("exit-nav");
      document.getElementsByTagName("body")[0].classList.remove("offcanvas-active");
    });
  }
}

export default MainNav;
