import { html } from '@polymer/polymer/polymer-element.js'
import { SharedStyle } from '../shared-style.js';

export const DebatesListStyle = html`
  ${SharedStyle}
  <style>
    .featured-debate-item h2.item-title {
      font: var(--h1-font);
    }

    .std-list-item.debate-list-item h2.item-title {
      font: var(--h2-font);
    }

    .std-list-item.debate-list-item.debate-finished-true h2.item-title {
      color: var(--secondary);
    }
    
  </style>
`;
