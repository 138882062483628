import { html } from '@polymer/polymer/polymer-element.js'
import { SharedStyle } from '../shared-style.js';

export const ShareButtonStyle = html`
  ${SharedStyle}
  <style>
    .share-btn {
      position: relative;
      background-color: var(--light-primary);
      color: var(--light);
      box-shadow: var(--box-shadow-lg);
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      width: 40px;
      height: 40px;
    }

    .share-btn:hover, .share-btn:visited {
      color: var(--light);
      box-shadow: var(--box-shadow-lg-active);
    }

    .share-btn.only-icon {
      border-radius: 50%;
    }

    .share-btn-widget.fab-true .share-btn {
      position: fixed;
      top: 75%;
      right: 30px;
      z-index: 50;
      width: 60px;
      height: 60px;
      opacity: 0.95;
    }

    :host(.fab-top) .share-btn-widget.fab-true .share-btn {
      top: 18% !important;
    }

    .share-btn-widget.fab-true .share-btn:hover {
      opacity: 1;
    }

    .share-btn-widget.fab-true .share-btn.only-icon i {
      font-size: 1.9rem;
    }
    .share-btn-widget.fab-true .share-btn.only-icon svg {
      height: 1.9rem;
    }

    .share-btn.only-icon span {
      display: none;
    }

    .share-btn.text-icon {
      font: var(--h3-light-font);
      padding: 0.4rem 0.8rem;
    }

    .share-btn.text-icon span {
      margin-left: 0.25rem;
    }

    .share-popover {
      display: none;
      border-color: var(--primary);
      box-shadow: var(--box-shadow-lg);
    }
    .share-popover .popover-arrow:before, .share-popover .popover-arrow:after {
      border-left-color: var(--light-primary);
    }
    
    .share-popover .popover-header {
      display: none;
    }

    .share-popover.fab-true .popover-header {
      display: block;
      background-color: var(--light-primary);
      color: var(--light);
      text-transform: uppercase;
      text-align: center;
      padding: 0.75rem 1rem;
      font: var(--h0-font);
    }

    .share-popover .popover-body {
      padding: 0;
    }
    .share-popover .popover-body .share-links {
      padding: 3%;
    }
    .share-popover .popover-body .share-links i,
    .share-popover .popover-body .share-links svg {
      font-size: 2.5em;
      color: var(--light-primary);
      padding: 0 0.5rem;
    }

    i.fas.fa-share-alt::before {
      content: '\\f1e0';
    }
    i.fab.fa-facebook::before {
      content: '\\f09a';
    }
    i.fab.fa-twitter::before {
      content: '\\f099';
    }
    i.fas.fa-clipboard::before {
      content: '\\f328';
    }
  </style>
`;
