import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { ListBehavior } from '../../behaviors/list-behavior.js'
import { I18nBehavior } from '../../behaviors/i18n-behavior.js'
import { SurveysListStyle } from './style.js';

export const SurveysList = class extends mixinBehaviors([I18nBehavior, ListBehavior], PolymerElement) {
  static get is() { return 'surveys-list'; }
  static get behaviors() { return [I18nBehavior, ListBehavior] }
  static get properties() { return { type: {value: 'survey'} } }
  static get template() {
    return html`
      ${SurveysListStyle}
      <div class="surveys-list standard-list" id="list">
        <template is="dom-repeat" items="[[items]]">
          <div class$="[[itemClass(type, item)]]" id$="[[type]]_[[item.id]]">
            <a href$="{{item.url}}">
              <div class="survey-item">
                <img src="{{item.img}}" class="item-image survey-item-image"></img>
                <div class="item-data survey-item-data">
                  <h2 class="item-title survey-item-title">{{item.title}}</h2>
                  <span class="item-date survey-item-date" inner-h-t-m-l="{{item.ends_at}}"></span>
                </div>
              </div>
            </a>
          </div>
        </template>

        <template is="dom-if" if="[[_availablePagination(paginate, items)]]">
          <button class="std-list-next-page btn btn-custom" on-click="getNextPage">
            <i class="fas fa-fw"></i>
            [[i18n('surveys_list.see_more')]]
          </button>
        </template>
      </div>
    `;
  }
  constructor() {
    super();
  }
}

customElements.get(SurveysList.is) || customElements.define(SurveysList.is, SurveysList);
